import React, { useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { mapEdgesToNodes } from '../lib/helpers';
import { useLoadMore } from '../lib/hooks';
import { breakpoints } from '../theme/mediaQueries';

import localize from '../components/localize';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHero from '../components/PageHero';
import PageFooter from '../components/PageFooter';
import BlogCard from '../components/Blog/BlogCard';
import OutlineBtnLg from '../components/Buttons/OutlineBtnLg';

const BlogPage = ({ data, pageContext, path }) => {
  const pageArray = mapEdgesToNodes(data.page);
  const pageInfo = pageArray[0];

  const postsArray = mapEdgesToNodes(data.posts);
  const numOfItemsToDisplay = 5;

  const [postsList, loadMore, hasMore, setLoadMore] = useLoadMore(postsArray, numOfItemsToDisplay);

  const postsListDisplay = postsList.map((post) => (
    <BlogCard
      key={post.id}
      post={post}
      lang={pageContext.locale}
      btnText={data.blogDictionary.viewOneBlog}
    />
  ));

  // Handle 'load more' button click
  const handleLoadMoreClick = () => {
    setLoadMore(true);
  };

  return (
    <Layout pageContext={pageContext} path={path} siteTitle={data.site.siteTitle}>
      <SEO title={pageInfo.title} image={pageInfo.heroImg.asset.fluid} />
      <header>
        <PageHero withImage fluidImg={pageInfo.heroImg.asset.fluid} pageTitle={pageInfo.title} />
      </header>
      <StyledCardContainer>{postsListDisplay}</StyledCardContainer>
      <PageFooter>
        {hasMore ? (
          <OutlineBtnLg
            btnText={data.blogDictionary.loadMoreBlogs}
            as="button"
            handleClick={handleLoadMoreClick}
          />
        ) : (
          <OutlineBtnLg btnText={data.blogDictionary.loadMoreBlogs} as="button" disabled />
        )}
      </PageFooter>
    </Layout>
  );
};

const StyledCardContainer = styled(Container)`
  margin-top: 4.875rem;
  margin-bottom: 0;

  & > a:nth-child(even) {
    @media (min-width: ${breakpoints.md}px) {
      .blog-card-left {
        order: 2;
      }
      .blog-card-right {
        order: 1;
      }
    }
  }
`;

export const query = graphql`
  query BlogPageQuery {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
    }
    page: allSanityPage(filter: { id: { eq: "-d01d65c9-52f9-5731-8fc7-29b43fd70810" } }) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          heroImg {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    blogDictionary: sanityDictionaryBlog {
      viewAllBlogs {
        _type
        de
        en
      }
      viewOneBlog {
        _type
        de
        en
      }
      loadMoreBlogs {
        _type
        de
        en
      }
    }
    posts: allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          headerImage {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
          publishedAt
          slug {
            current
          }
          _rawContent(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  }
`;

export default localize(BlogPage);
